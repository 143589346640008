import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { cacheQueryConfig, handleFetchApi } from "../../configs/apiRequest";
import { confirmStartAssignment } from "../../utils/exercises";

function AssignmentPreview(props) {
    const { exercise_id } = useParams();
    const navigate = useNavigate();

    const fetchAssignment = async () => {
        const { data: assignment } = await handleFetchApi("get", `/assignment/${exercise_id}`);
        const { data: grading } = await handleFetchApi("get", `/assignment/${exercise_id}/grading`);
        if (!grading || !grading[0]) {
            return confirmStartAssignment({ assignment, navigate });
        }

        if (grading && grading[0]) {
            if (!grading[0]?.submittedAt) {
                return navigate(`/assignment/${exercise_id}/grading/${grading[0]?.id}`);
            }
        }
    };

    const { data, error, isLoading } = useQuery(["assignment", exercise_id], fetchAssignment, cacheQueryConfig({ time: 60 }));

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading assignment</div>;

    return (
        <div>
            <h1>{data.title}</h1>
            <p>{data.description}</p>
        </div>
    );
}

export default AssignmentPreview;
